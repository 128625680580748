
import { defineComponent } from "vue";
import Header from "@/components/parts/Header.vue";
import BottomNavigation from "@/components/parts/BottomNavigation.vue";
import TagSearchPage from "@/components/pages/TagSearchPage.vue";
import { onBeforeRouteUpdate } from "vue-router";

export default defineComponent({
  components: {
    Header,
    BottomNavigation,
    TagSearchPage
  },
  setup() {    
    onBeforeRouteUpdate(async (to) => { // タグ検索ページにいる時に、別のタグに関するタグ検索ページに遷移しようとした時に実行
      window.location.href = `${document.location.origin}/tag-search/${to.params.tag}`;
    });
  }
});
