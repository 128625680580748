
import { defineComponent, reactive } from "vue";
import ArticleSummary from "@/components/parts/ArticleSummary.vue";
import { getArticlesFromTag } from "@/components/firebaseOperations";
import { useRouter } from "vue-router";
import { cleansingTag } from "@/components/aboutArticleFunc";
import InfiniteScroll from "infinite-loading-vue3";
import constants from "@/components/constants";
import techIcons from "@/components/techIcons";

export default defineComponent({
  components: {
    ArticleSummary,
    InfiniteScroll,
  },
  async setup() {
    const router = useRouter();

    const state = reactive({
      articles: {} as { [key: string]: any },
      name: "",
      techIconPath: "",
      infiniteScrollMessage: "",
      infiniteScrollNoResult: false,
    });

    /// 初期化処理
    /// @param {string} text - タグ名
    const init = async (text: string) => {
      state.techIconPath = "";
      state.name = cleansingTag(text);
      const techIconsKey = state.name.replace(/_/g, "");

      const tag = (techIcons.icons as any)[techIconsKey];
      if (tag != undefined) {
        state.name = tag["exactName"];
        state.techIconPath = tag["path"];
      }

      state.articles = await getArticlesFromTag(state.name, null);
    };

    await init(router.currentRoute.value.params.tag as string);

    if (state.articles.docs.size == 0) {
      // 入力されたタグを含む記事が存在していなかった場合
      router.push({
        path: "/not-found",
      });
    }

    /// 最後の要素に達した時に実行される関数
    const infiniteHandler = async () => {
      if (
        state.articles.docs.size >= constants.UPDATE_BASE_COUNT &&
        !state.infiniteScrollNoResult
      ) {
        // 無限スクロールのための更新処理が、有効状態だった場合
        const articles = await getArticlesFromTag(
          state.name,
          state.articles.lastDocSnapshot
        );

        if (articles.docs.size !== 0) {
          try {
            for (const article of articles.docs.values()) {
              state.articles.docs.set(article.id, article);
            }

            state.articles.lastDocSnapshot = articles.lastDocSnapshot;
          } catch (error) {
            console.error(error);
          }
        } else {
          state.infiniteScrollNoResult = true;
        }
      }
    };

    return {
      state,
      infiniteHandler,
    };
  },
});
